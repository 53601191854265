*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: 'Helvetica', sans-serif
    font-weight: 400

.header
    height: 75px
    background: #D9FFCE

.question
    width: 90vw
    min-height: calc( 100vh - 120px )
    margin: auto
    text-align: center

    h1
        padding: 30px 0

    h2
        height: 30px
        margin: 10px 0

    img
        width: 100%
        height: 225px
        object-fit: contain
        display: flex

    button
        font-size: 20px
        padding: 10px 50px
        border-radius: 20px
        border: 0
        background: #BCFCFF
        width: 210px
        margin: 10px 0

.menu
    width: 90vw
    margin: 30px auto

    li
        list-style: none
        margin: 10px 0
        text-align: center
        margin: 15px 0
        width: 100%
        background: #FFE2BF
        padding: 15px 50px
        border-radius: 20px

        a
            text-decoration: none
            color: #000000
